import React from 'react'
import PropTypes from 'prop-types'

import DocsCategoryTree from 'components/DocsCategoryTree'

import bg from './CategoryTree.bg.json'
import en from './CategoryTree.en.json'

const content = {
  'bg': bg,
  'en': en
}

const CategoryTree = ({location}) => (
  <DocsCategoryTree product='politis' content={content} location={location} />
)

CategoryTree.propTypes = {
  location: PropTypes.object.isRequired
}

export default CategoryTree
